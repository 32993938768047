




































































































































import { Component } from "vue-property-decorator";
import { Collection, ProductLineName } from "@/models/products/collection";
import ProductService from "@/services/product_service";
import ConfigFileService from "@/services/config_file_service";
import { ProductType } from "@/models/products/product";
import { UmbrellaModel, UmbrellaType } from "@/models/products/umbrella";
import { mixins } from "vue-class-component";
import {
  BaseStore,
  LayoutStore,
  ShapeStore,
  CanopyStore,
  FrameStore,
} from "@/mixins/store";
import { ConfigCreator } from "@/mixins/configurator";
import { APIError, NotFoundError, ViewerError } from "@/services/error_service";
import { ConfigFile } from "@/models/configurator/config_file";
import { Auth } from "@/mixins/auth";
import { StockMixin } from "@/mixins/stock";
import AuthService from "@/services/auth_service";
import AccordionSection from "@/components/designer/reusable/AccordionSection.vue";
import PageHeadline from "@/components/designer/reusable/PageHeadline.vue";
import Header from "@/components/designer/header/Header.vue";
import { ClearAllPresetDesigns } from "@/mixins/helpers";

@Component({
  components: {
    AccordionSection,
    Header,
    PageHeadline,
  },
})
export default class CollectionMenu extends mixins(
  BaseStore,
  LayoutStore,
  ShapeStore,
  CanopyStore,
  FrameStore,
  ConfigCreator,
  Auth,
  StockMixin,
  ClearAllPresetDesigns
) {
  protected selection = {} as Collection;
  protected type: UmbrellaType = UmbrellaType.Market;
  protected umbrellaType = UmbrellaType;
  protected umbrellas: Collection[] = [];
  protected productService = new ProductService();
  protected configFileService = new ConfigFileService();
  protected authService = new AuthService();
  protected ProductLineName = ProductLineName;
  async created(): Promise<void> {
    // if (this.$route.query.handle) {
      const loader = this.$loading.show({ opacity: 1 });
      this.umbrellas = await this.getProductLines();
      this.selection = this.umbrellas.find((u) => {
        return (
          u.handle === "greenwich"
          // u.handle === (this.$route.query.handle as string).replaceAll("-", "_")
        );
      })!;
      if (!this.selection) {
        ViewerError.redirect("Invalid handle.");
      } else {
        loader.hide();
        await this.start();
      }
    // } else if (this.$route.query.design) {
    //   const fileShareId = this.$route.query.design as string;
    //   if (this.$route.query.edit) {
    //     const loader = this.$loading.show(
    //       { opacity: 1 },
    //       {
    //         before: this.$createElement("h1", "Loading..."),
    //       }
    //     );
    //     if (this.$route.query.design !== "redirect") {
    //       const configFile = await this.fetchSharedConfigFile(fileShareId);
    //       await this.transformConfigFileAndSaveToStore(configFile);
    //     }
    //     this.clearAllPresetDesigns();
    //     this.$router.replace("/designer");
    //     loader.hide();
    //   } else {
    //     this.$router.push("/view?design=" + fileShareId);
    //   }
    // } else {
    //   this.umbrellas = await this.getProductLines();
    //   this.getDefaultSelection();
    //   // The Configurator absolutely needs the store to be populated before it can be used.
    //   // If not populated already, we set it with the default umbrella config here.
    //   if (!this.collection || !this.umbrellaModel) {
    //     await this.populateStore();
    //   }
    //   await this.checkAuth();
    // }
  }

  protected async fetchSharedConfigFile(shareId: string): Promise<ConfigFile> {
    let file = {} as ConfigFile;
    try {
      const res = await this.configFileService.getShareFile(shareId);
      file = res;
    } catch (err) {
      if (err instanceof NotFoundError) {
        NotFoundError.popup(err.message, err.statusCode);
      } else {
        APIError.popup(err.message, err.statusCode);
      }
    }
    return file;
  }

  /** Fix for weird vue transition/animation issues */
  protected beforeLeave(el: any): void {
    const { marginLeft, marginTop, width, height } = window.getComputedStyle(
      el
    );
    el.style.left = `${el.offsetLeft - parseFloat(marginLeft)}px`;
    el.style.top = `${el.offsetTop - parseFloat(marginTop)}px`;
    el.style.width = width;
    el.style.height = height;
  }

  protected async getProductLines(): Promise<Collection[]> {
    let umbrellas: Collection[] = [];
    try {
      const res = await this.productService.getProducts({
        category: ProductType.Umbrella,
        sort: "productline",
      });
      umbrellas = res;
    } catch (err) {
      if (err instanceof NotFoundError) {
        NotFoundError.popup(err.message, err.statusCode);
      } else {
        APIError.popup(err.message, err.statusCode);
      }
    }
    return umbrellas;
  }

  protected async start(): Promise<void> {
    try {
      const configFile = this.getDefaultConfigFile();
      await this.transformConfigFileAndSaveToStore(configFile);
      await this.$router.push("/designer");
    } catch (err) {
      APIError.redirect(
        "There was an error loading the umbrella configuration. The selected umbrella and/or its parts or fabric may no longer be available. If you believe this is an error, please contact Frankford Umbrellas directly for more information at info@frankfordumbrellas.com."
      );
    }
  }

  protected async populateStore(): Promise<void> {
    try {
      const configFile = this.getDefaultConfigFile();
      await this.transformConfigFileAndSaveToStore(configFile);
    } catch (err) {
      console.error("Error populating store", err);
    }
  }

  protected getDefaultConfigFile(): ConfigFile {
    if (this.selection.config_file) {
      const file: any = this.selection.config_file as any;
      return file[0]; //TODO, THIS SHOULDNT BE AN ARRAY
    } else {
      throw new ViewerError(
        "No default configuration for this umbrella found."
      );
    }
  }

  protected async selectProduct(umbrella: Collection): Promise<void> {
    this.selection = umbrella;
    await this.start();
  }

  protected selectTab(type: UmbrellaType): void {
    this.type = type;
    this.getDefaultSelection();
  }

  protected getDefaultSelection(): void {
    const defaultSelection = this.umbrellas.filter(
      (umbrella) => umbrella.umbrella_type === this.type
    );
    if (this.type === UmbrellaType.Market) {
      this.selection = defaultSelection.find(
        (e) => e.handle === "greenwich"
      )!;
    }
    // else if (this.type === UmbrellaType.Cantilever) {
    //   this.selection = defaultSelection.find((e) => e.handle === "eclipse")!;
    // } else if (this.type === UmbrellaType.Giant) {
    //   this.selection = defaultSelection.find(
    //     (e) => e.handle === "monterey_g_series"
    //   )!;
    // }
    // else if (this.type === UmbrellaType.Patio) {
    //   this.selection = defaultSelection.find((e) => e.handle === "laurel")!;
    // } else if (this.type === UmbrellaType.Beach) {
    //   this.selection = defaultSelection.find((e) => e.handle === "avalon")!;
    // }
  }

  protected get umbrellasFiltered() {
    return this.umbrellas.filter(
      (umbrella) =>
        umbrella.umbrella_type === this.type && umbrella.handle !== "shade_star"
    );
  }

  protected formatTitle(title: string, part: string): string {
    let result = "";
    if (title.indexOf(" ")) {
      if (part == "a") {
        result = title.substr(0, title.indexOf(" "));
      } else {
        result = title.substr(title.indexOf(" ") + 1);
      }
    } else {
      return title;
    }

    return result;
  }

  protected getImg(umbrella: Collection): string {
    let img = "";

    img = umbrella.image as string;

    return img + "?width=285";
  }
}
